<template>
	<NuxtLink
		v-bind="$attrs"
		:external="external"
		:target="target"
		:class="[
			display,
			textColor,
			hover
				? 'duration-300 ease-out hover:text-blue-400 hover:decoration-blue-400'
				: 'duration-300 ease-out',
			pointer ? 'hover:cursor-pointer' : '',
			fontWeight,
			fontSize,
			lineHeight,
			underline ? 'underline underline-offset-2' : '',
			underlineColor,
		]"
		@click.stop="onClick"
	>
		<slot>{{ text }}</slot>
	</NuxtLink>
</template>

<script setup>
const props = defineProps({
	textColor: {
		type: String,
		default: 'text-gray-575',
		required: false,
	},
	fontWeight: {
		type: String,
		default: '',
		required: false,
	},
	fontSize: {
		type: String,
		default: 'text-lg',
		required: false,
	},
	lineHeight: {
		type: String,
		default: 'leading-normal',
		required: false,
	},
	underline: {
		type: Boolean,
		default: true,
		required: false,
	},
	underlineColor: {
		type: String,
		default: 'decoration-gray-575',
		required: false,
	},
	// this is to auto trim extra spaces when the slot contains lines
	display: {
		type: String,
		default: 'inline',
		required: false,
	},
	hover: {
		type: Boolean,
		default: true,
		required: false,
	},
	pointer: {
		type: Boolean,
		default: false,
		required: false,
	},
	secondary: {
		type: Boolean,
		default: true,
		required: false,
	},
	external: {
		type: Boolean,
		default: true,
	},
	text: {
		type: String,
		default: 'Default Link',
	},
	target: {
		type: String,
		default: '',
	},
})

const emit = defineEmits(['click'])

const onClick = (event) => {
	emit('click', event)
}
</script>
